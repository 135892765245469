import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect } from "react";
import Lottie from "react-lottie";
import { useFetchJson } from "../../../hooks/useFetchJson";
import { useOnScreen } from "../../../hooks/useOnScreen";

export const Quotes = () => {
  const breakpoints = useBreakpoint();

  const { language } = useI18next();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: handleLottieSource(language),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const langSufix = language ==="en" ? "" : language === "es" ? "ES" : "PR";
  const jsonName = "data" + langSufix + ".json";

  const [data, fetchData] = useFetchJson("/json/quotes/" + jsonName);

  const [ref, isVisible] = useOnScreen();

  useEffect(() => {
    if(isVisible && data === null) fetchData();
  }, [isVisible,data,fetchData])

  return (
    <div
      style={{
        overflow: "hidden",
        height: breakpoints.sm ? 300 : "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={ref}
    >
      {isVisible && data !== null && <Lottie
        options={{...defaultOptions, animationData: data}}
        height={breakpoints.sm ? "auto" : "100%"}
        width='100%'
        style={{ transform: breakpoints.sm ? "scale(2.2)" : "scale(1)" }}
      />}
    </div>
  );
};
