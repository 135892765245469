import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import calendarButton from "../../../assets/image/calendarButton.svg";
import styles from "./floating-see-demo.module.scss";
import { Link } from "gatsby";

const FloatingSeeDemo = ({ white }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link
        id="schedule_demo"
        to="/schedule-demo"
        // style={{backgroundColor: white && "#0c3e00"}}
      >
        <p
        // style={{background: white && "#0c3e00"}}
        >
          {t("MEETING_TEXT")}
        </p>
        <img 
          src={calendarButton} 
          alt="schedule a demo" 
          loading="lazy" 
        />
      </Link>
    </div>
  );
};

export default FloatingSeeDemo;
