import React, { useEffect, useState, useRef, useMemo } from "react";
import "@styles/index.scss";
import loadable from "@loadable/component";
import CelDesktopEN from "../assets/video/CelPrimeraInstancia.mp4";
import CelDesktopES from "../assets/video/Cel1Esp.mp4";
import CelDesktopPR from "../assets/video/Cel1portugues.mp4";
import TabletEN from "../assets/video/Tablet.mp4";
import videoEN_1 from "../assets/video/videoEN_1.mp4";
import videoEN_2 from "../assets/video/videoEN_2.mp4";
import videoES_1 from "../assets/video/videoES_1.mp4";
import videoES_2 from "../assets/video/videoES_2.mp4";
import videoPR_1 from "../assets/video/videoPR_1.mp4";
import videoPR_2 from "../assets/video/videoPR_2.mp4";
import PlatformHero from "../assets/image/PlatformHero.png";
import LaptopPlatformEN from "../assets/video/LaptopPlatformEN.mp4";
import LaptopPlatformES from "../assets/video/LaptopPlatformES.mp4";
import LaptopPlatformPR from "../assets/video/LaptopPlatformPR.mp4";
import video1Mobile from "../assets/video/Cel1_mobile.mp4";
import Cel1PR from "../assets/video/Cel1PR.mp4";
import Cel1ES from "../assets/video/Cel1ES.webm";
import Cel2PR from "../assets/video/Cel2PR.mp4";
import Cel2ES from "../assets/video/Cel2ES.mp4";
import video2Mobile from "../assets/video/Cel2_mobile.mp4";
import tabletMobile from "../assets/video/Tablet_mobile.mp4";
import laptop1Mobile from "../assets/video/Laptop1_Mobile.mp4";
import laptop1MobileES from "../assets/video/Laptop1_MobileES.mp4";
import laptop1MobilePR from "../assets/video/Laptop1_MobilePR.mp4";
import laptop2Mobile from "../assets/video/Laptop2_mobile.mp4";
import laptop2MobileES from "../assets/video/Laptop2_mobileES.mp4";
import laptop2MobilePR from "../assets/video/Laptop2_mobilePR.mp4";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";
import ReactTextTransition from "react-text-transition";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Quotes } from "@atoms/Quotes";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useOnScreen } from "../hooks/useOnScreen";
import SEO from "../components/Seo";
import ScrollTopButton from "@molecules/ScrollTopButton";
import SeeDemo from "@molecules/SeeDemo";
import ImgScroll from "@molecules/ImgScroll";
import { isBrowser } from "@utils/isBrowser";
import FloatingSeeDemo from "@molecules/FloatingSeeDemo";
import PipeDriveChat from "@utils/pipedriveChat";
const TransformIdeas = loadable(() => import("@organism/TransformIdeas"));
const SearchVideo = loadable(() => import("@organism/SearchVideo"));
const CustomerSay = loadable(() => import("@organism/CustomerSay"));
const Testimonies = loadable(() => import("@organism/Testimonies/Testimonies"));

const hide = { opacity: 0, y: 20 };
const show = { opacity: 1, y: 0 };

function handleLaptopDesktop(val) {
  switch (val) {
    case "en":
      return LaptopPlatformEN;
    case "es":
      return LaptopPlatformES;
    case "pr":
      return LaptopPlatformPR;
    default:
      return LaptopPlatformEN;
  }
}

function handleCelDesktop(val) {
  switch (val) {
    case "en":
      return CelDesktopEN;
    case "es":
      return CelDesktopES;
    case "pr":
      return CelDesktopPR;
    default:
      return CelDesktopEN;
  }
}

function handleCel1Mobile(val) {
  switch (val) {
    case "en":
      return video1Mobile;
    case "es":
      return Cel1ES;
    case "pr":
      return Cel1PR;
    default:
      return video1Mobile;
  }
}

function handleCel2Mobile(val) {
  switch (val) {
    case "en":
      return video2Mobile;
    case "es":
      return Cel2ES;
    case "pr":
      return Cel2PR;
    default:
      return video2Mobile;
  }
}

function handleTabletDesktop(val) {
  switch (val) {
    case "en":
      return TabletEN;
    case "es":
      return TabletEN;
    case "pr":
      return TabletEN;
    default:
      return TabletEN;
  }
}

function handleVideoPart1(val) {
  switch (val) {
    case "en":
      return videoEN_1;
    case "es":
      return videoES_1;
    case "pr":
      return videoPR_1;
    default:
      return videoEN_1;
  }
}
function handleVideoPart2(val) {
  switch (val) {
    case "en":
      return videoEN_2;
    case "es":
      return videoES_2;
    case "pr":
      return videoPR_2;
    default:
      return videoEN_2;
  }
}

function handleLaptop1Mobile(val) {
  switch (val) {
    case "en":
      return laptop1Mobile;
    case "es":
      return laptop1MobileES;
    case "pr":
      return laptop1MobilePR;
    default:
      return laptop1Mobile;
  }
}

function handleLaptop2Mobile(val) {
  switch (val) {
    case "en":
      return laptop2Mobile;
    case "es":
      return laptop2MobileES;
    case "pr":
      return laptop2MobilePR;
    default:
      return laptop2Mobile;
  }
}

const Platform = ({ location }) => {
  const { t } = useTranslation();
  const [first, isFirstVisible] = useOnScreen({ threshold: 0.3 });
  const [second, isSecondVisible] = useOnScreen({ threshold: 0.3 });
  const [third, isThirdVisible] = useOnScreen({ threshold: 0.3 });
  const [fourth, isFourthVisible] = useOnScreen({ threshold: 0.3 });
  const [fifth, isFifthVisible] = useOnScreen({ threshold: 0.3 });

  const ideas = useRef();
  const [ideasVisible, setIdeasVisible] = useState(false);
  const firstVideo = useRef();
  const secondVideo = useRef();
  const thirdVideo = useRef();
  const fourthVideo = useRef();
  const fifthVideo = useRef();

  const [chatStyle, setChatStyle] = useState(``);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const { language } = useI18next();

  const handleOnLoad = () => {
    setTimeout(() => {
      setChatStyle(`textarea[class^="inputField_"]
      {
        color: #FFFFFF !important; 
         -webkit-text-fill-color: #FFFFFF !important;
      } `);
    }, 2500);
  };
  const openChat = () => {
    setinitChat(true);

    setClientChat(t("JIVOCHAT"));
    handleOnLoad();
  };

  useEffect(() => {
    setChatStyle(`textarea[class^="inputField_"]
    {
      color: #FFFFFF !important; 
       -webkit-text-fill-color: #FFFFFF !important;
    } `);
    if (initChat) {
      if (isBrowser && clientChat !== false && clientChat !== t("JIVOCHAT")) {
        window.location.reload();
      }
    }
  }, []);

  const texts = useMemo(
    () => [
      t("PLATFORM.TITLESWIPE.OPTION1"),
      t("PLATFORM.TITLESWIPE.OPTION2"),
      t("PLATFORM.TITLESWIPE.OPTION3"),
      t("PLATFORM.TITLESWIPE.OPTION4"),
      t("PLATFORM.TITLESWIPE.OPTION5"),
    ],
    [t]
  );

  const breakpoints = useBreakpoint();
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prev) => prev + 1);
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isFirstVisible && firstVideo.current) {
      firstVideo.current.play();
    }
  }, [isFirstVisible]);

  useEffect(() => {
    if (isSecondVisible && secondVideo.current) {
      secondVideo.current.play();
    }
  }, [isSecondVisible]);

  useEffect(() => {
    let timeout;
    if (isThirdVisible && thirdVideo.current) {
      timeout = setTimeout(() => {
        thirdVideo.current.play();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isThirdVisible]);

  useEffect(() => {
    if (isFourthVisible) {
      fourthVideo.current.play();
    }
  }, [isFourthVisible]);

  useEffect(() => {
    if (isFifthVisible) {
      setTimeout(() => {
        fifthVideo.current.play();
      }, 1000);
    }
  }, [isFifthVisible]);

  useEffect(() => {
    if (isBrowser && typeof document !== "undefined" && ideas.current) {
      window.scrollTo(0, 0);
      const rect = ideas.current.getBoundingClientRect();
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );

      window.addEventListener("scroll", () => {
        if (window.scrollY >= rect.top + viewHeight) {
          setIdeasVisible(true);
        }
        if (window.scrollY < rect.top + viewHeight) {
          setIdeasVisible(false);
        }
      });

      return () => {
        window.removeEventListener("scroll", () => {
          if (window.scrollY >= rect.top + viewHeight) {
            setIdeasVisible(true);
          }
          if (window.scrollY < rect.top + viewHeight) {
            setIdeasVisible(false);
          }
        });
      };
    }
  }, []);

  return (
    <>
      <SEO
        title={t("SEO.PLATFORM.TITLE")}
        description={t("SEO.PLATFORM.DESCRIPTION")}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <Navbar platform location={location} ideasVisible={ideasVisible} />

      {/* DESKTOP */}
      <div
        style={{
          background: "white",
          overflow: "hidden",
          display: !breakpoints.md ? "block" : "none",
        }}
      >
        <section
          style={{
            minHeight: "100vh",
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "6%",
            position: "relative",
          }}
        >
          <img
            src={PlatformHero}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              width: "100%",
            }}
            alt="Bg platform"
            loading="lazy"
          />
          <motion.h1
            initial={hide}
            animate={show}
            transition={{
              type: "spring",
              stiffness: 20,
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 15,
              margin: 0,
            }}
            className="headerBlue"
          >
            {t("PLATFORM.TITLE1")}
            <br />
            <ReactTextTransition
              direction="up"
              noOverflow={true}
              text={texts[textIndex % texts.length]}
              style={{
                margin: "0 auto",
                minHeight: breakpoints.md ? 88 : "inherit",
              }}
            />
          </motion.h1>
          <motion.p
            initial={hide}
            animate={show}
            className="po6"
            transition={{
              type: "spring",
              stiffness: 20,
            }}
            style={{
              textAlign: "center",
              margin: "0 20px 15px",
              maxWidth: 500,
            }}
          >
            {`"${t("PLATFORM.SUBTITLE1")}"`}
          </motion.p>
          <motion.p
            initial={hide}
            animate={show}
            transition={{
              type: "spring",
              stiffness: 20,
            }}
            className="byTech"
            style={{ marginBottom: 30, textAlign: "center" }}
          >
            {t("PLATFORM.QUOTEBY")}
          </motion.p>

          <motion.p
            initial={hide}
            animate={show}
            // href="#demo"
            // onClick={() => scrollTo("/platform", "demo")}
            style={{
              position: "absolute",
              top: "350px",
              color: "#000000",
              textDecoration: "none",
              WebkitTransition: 0.5,
              transition: 0.5,
            }}
            transition={{
              type: "spring",
              stiffness: 20,
            }}
          >
            <ImgScroll />
          </motion.p>
          <SeeDemo title={t("DEMO.SCHEDULE")} />
        </section>

        <section
          ref={first}
          style={{ margin: "auto", maxWidth: 1240, background: "white" }}
        >
          <motion.h3
            initial={hide}
            animate={isFirstVisible ? show : hide}
            transition={{ duration: 1 }}
            style={{
              fontSize: 12,
              letterSpacing: "0.175em",
              color: "#A0A0A0",
              textAlign: "center",
            }}
          >
            {t("PLATFORM.IMPACT_ON")}{" "}
            <span style={{ color: "black" }}>{t("PLATFORM.TEAMS")}</span>
          </motion.h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#000",
                padding: "0 20px",
                maxWidth: 500,
                margin: "0 auto",
              }}
            >
              <motion.h2
                initial={hide}
                transition={{ duration: 1 }}
                animate={isFirstVisible ? show : hide}
                style={{ textAlign: "left", fontSize: 32 }}
              >
                {t("PLATFORM.TITLE2")}
              </motion.h2>
              <motion.p
                initial={hide}
                transition={{ duration: 1, delay: 0.2 }}
                animate={isFirstVisible ? show : hide}
                className="po6"
                style={{
                  fontSize: 18,
                  lineHeight: "155.5%",
                  textAlign: "left",
                }}
              >
                {t("PLATFORM.SUBTITLE2")}
              </motion.p>
            </div>

            <motion.video
              ref={firstVideo}
              initial={{
                x: "10%",
                y: -500,
                rotate: 20,
                transition: { duration: 1 },
              }}
              animate={
                isFirstVisible
                  ? { x: "0%", y: 0, rotate: 6, transition: { duration: 1 } }
                  : {
                      x: "10%",
                      y: -500,
                      rotate: 20,
                      transition: { duration: 1 },
                    }
              }
              src={handleCelDesktop(language)}
              style={{
                width: "35%",
                marginTop: 100,
                transform: "rotate(6deg)",
              }}
              muted
              playsInline
              loop
            >
              Not supported
            </motion.video>
          </div>
        </section>
        <Progress
          style={{
            transform: "rotate(180deg)",
            margin: "0 auto 100px",
          }}
        />
        {/* 2 */}
        <section ref={second} style={{ margin: "auto", width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <motion.video
              ref={secondVideo}
              initial={{ rotate: -3, opacity: 0, transition: { duration: 1 } }}
              src={handleTabletDesktop(language)}
              animate={
                isSecondVisible
                  ? { rotate: 0, opacity: 1, transition: { duration: 1 } }
                  : { rotate: -3, opacity: 0, transition: { duration: 1 } }
              }
              style={{
                width: "60%",
                transformOrigin: "bottom right",
              }}
              muted
              loop
              autoPlay
              playsInline
            >
              Not supported
            </motion.video>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#000",
                padding: "0 20px",
                marginRight: "auto",
                maxWidth: 500,
              }}
            >
              <motion.h2
                initial={hide}
                transition={{ duration: 1 }}
                animate={isSecondVisible ? show : hide}
                style={{ textAlign: "left", fontSize: 32 }}
              >
                {t("PLATFORM.TITLE3")}
              </motion.h2>
              <motion.p
                className="po6"
                initial={hide}
                transition={{ duration: 1, delay: 0.2 }}
                animate={isSecondVisible ? show : hide}
                style={{
                  fontSize: 18,
                  lineHeight: "155.5%",
                  textAlign: "left",
                }}
              >
                {t("PLATFORM.SUBTITLE3")}
              </motion.p>
            </div>
          </div>
        </section>
        {/* 2fin */}
        <Progress
          style={{
            margin: "60px auto",
          }}
        />
        {/* 3 */}
        <section ref={third} style={{ width: "100%", margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#000",
                padding: "0 20px",
                maxWidth: 500,
                marginLeft: "auto",
              }}
            >
              <motion.h2
                initial={hide}
                transition={{ duration: 1 }}
                animate={isThirdVisible ? show : hide}
                style={{ textAlign: "left", fontSize: 32 }}
              >
                {t("PLATFORM.TITLE4")}
              </motion.h2>
              <motion.p
                initial={hide}
                transition={{ duration: 1, delay: 0.2 }}
                animate={isThirdVisible ? show : hide}
                className="po6"
                style={{
                  fontSize: 18,
                  lineHeight: "155.5%",
                  textAlign: "left",
                }}
              >
                {t("PLATFORM.SUBTITLE4")}
              </motion.p>
            </div>

            <motion.video
              ref={thirdVideo}
              initial={{ x: 100, transition: { duration: 1 } }}
              animate={
                isThirdVisible
                  ? { x: 0, transition: { duration: 1 } }
                  : { x: 100, transition: { duration: 1 } }
              }
              src={handleLaptopDesktop(language)}
              style={{ width: "60%" }}
              muted
              loop
              autoPlay
              playsInline
            >
              Not supported
            </motion.video>
          </div>
        </section>
        {/* 3 fin */}

        <Circle style={{ margin: "100px auto" }} />

        <section ref={fourth} style={{ width: "100%", margin: "100px auto 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              padding: "0 20px",
            }}
          >
            <motion.h3
              style={{
                fontSize: 12,
                letterSpacing: "0.175em",
                color: "#A0A0A0",
                textAlign: "center",
              }}
              initial={hide}
              transition={{ duration: 1 }}
              animate={isFourthVisible ? show : hide}
            >
              {t("PLATFORM.IMPACT_ON")}{" "}
              <span style={{ color: "black" }}>
                DIGITAL
                <br /> & HUMAN CHANNELS
              </span>
            </motion.h3>
          </div>
          <motion.video
            ref={fourthVideo}
            src={handleVideoPart1(language)}
            style={{ width: "100%" }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </motion.video>
        </section>
        <section ref={fifth} style={{ width: "100%", margin: "100px auto 0" }}>
          <motion.video
            ref={fifthVideo}
            src={handleVideoPart2(language)}
            style={{ width: "100%" }}
            muted
            loop
            autoPlay={isFifthVisible}
            playsInline
          >
            Not supported
          </motion.video>
        </section>

        <section style={{ maxWidth: 900, margin: "auto", display: "none" }}>
          <video
            src={video2Mobile}
            style={{ width: 500, margin: "60px auto 0" }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              marginBottom: 287,
            }}
          >
            <h2 style={{ textAlign: "center", fontSize: 32, color: "#337BE4" }}>
              {t("PLATFORM.TITLE5_1")}
              <br />
              {t("PLATFORM.TITLE5_2")}
            </h2>
            <p
              className="po6"
              style={{
                fontSize: 18,
                lineHeight: "155.5%",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.SUBTITLE5")}
            </p>
          </div>
        </section>
        <SeeDemo title={t("DEMO.SCHEDULENOW")} />
      </div>

      {/* MOBILE */}
      <section
        style={{
          backgroundColor: "white",
          paddingTop: 150,
          display: breakpoints.md ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "auto",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 32,
              padding: 15,
              width: 373,
            }}
            className="headerBlue"
          >
            {t("PLATFORM.TITLE1")}
            <br />
            <ReactTextTransition
              direction="up"
              noOverflow={true}
              text={texts[textIndex % texts.length]}
              style={{
                margin: "0 auto",
                minHeight: breakpoints.md ? 88 : "inherit",
              }}
            />
          </div>
          <p
            className="po6"
            style={{ textAlign: "center", margin: "0 20px 15px" }}
          >
            {`"${t("PLATFORM.SUBTITLE1")}"`}
          </p>
          <p
            className="byTech"
            style={{ marginBottom: 30, textAlign: "center" }}
          >
            {t("PLATFORM.QUOTEBY")}
          </p>

          <p
            style={{
              textDecoration: "none",
              color: "#000000",
            }}
          >
            <ImgScroll />
          </p>

          <SeeDemo title={t("DEMO.SCHEDULE")} />

          <video
            autoPlay
            src={handleCel1Mobile(language)}
            style={{ width: "100%", marginTop: 100 }}
            muted
            playsInline
            loop
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              padding: "0 20px",
            }}
          >
            <h3
              style={{
                fontSize: 12,
                letterSpacing: "0.175em",
                color: "#A0A0A0",
              }}
            >
              {t("PLATFORM.IMPACT_ON")}{" "}
              <span style={{ color: "black" }}>{t("PLATFORM.TEAMS")}</span>
            </h3>
            <h2 style={{ textAlign: "center", fontSize: 25 }}>
              {t("PLATFORM.TITLE2")}
            </h2>
            <p
              className="po6"
              style={{
                fontSize: 18,
                lineHeight: "155.5%",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.SUBTITLE2")}
            </p>

            <Progress style={{ marginTop: 60 }} />
          </div>

          <video
            src={tabletMobile}
            style={{ width: "100%" }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              padding: "0 20px",
            }}
          >
            <h3
              style={{
                fontSize: 12,
                letterSpacing: "0.175em",
                color: "#A0A0A0",
              }}
            >
              {t("PLATFORM.IMPACT_ON")}{" "}
              <span style={{ color: "black" }}>{t("PLATFORM.TEAMS")}</span>
            </h3>
            <h2 style={{ textAlign: "center", fontSize: 25 }}>
              {t("PLATFORM.TITLE3")}
            </h2>
            <p
              className="po6"
              style={{
                fontSize: 18,
                lineHeight: "155.5%",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.SUBTITLE3")}
            </p>

            <Progress style={{ marginTop: 60, transform: "rotate(180deg)" }} />
          </div>

          <video
            src={handleLaptop1Mobile(language)}
            style={{ width: "100%" }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              padding: "0 20px",
            }}
          >
            <h3
              style={{
                fontSize: 12,
                letterSpacing: "0.175em",
                color: "#A0A0A0",
              }}
            >
              {t("PLATFORM.IMPACT_ON")}{" "}
              <span style={{ color: "black" }}>{t("PLATFORM.TEAMS")}</span>
            </h3>
            <h2 style={{ textAlign: "center", fontSize: 25 }}>
              {t("PLATFORM.TITLE4")}
            </h2>
            <p
              className="po6"
              style={{
                fontSize: 18,
                lineHeight: "155.5%",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.SUBTITLE4")}
            </p>

            <Circle style={{ marginTop: 60 }} />
          </div>

          <video
            src={handleLaptop2Mobile(language)}
            style={{ width: "100%" }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              padding: "0 20px",
            }}
          >
            <h3
              style={{
                fontSize: 12,
                letterSpacing: "0.175em",
                color: "#A0A0A0",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.IMPACT_ON")}{" "}
              <span style={{ color: "black" }}>
                DIGITAL
                <br /> & HUMAN CHANNELS
              </span>
            </h3>

            <Circle style={{ marginTop: 60 }} />
          </div>

          <video
            src={handleCel2Mobile(language)}
            style={{ width: "100%", marginTop: 60 }}
            muted
            loop
            autoPlay
            playsInline
          >
            Not supported
          </video>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#000",
              marginBottom: 287,
            }}
          >
            <h2 style={{ textAlign: "center", fontSize: 25, color: "#337BE4" }}>
              {t("PLATFORM.TITLE5_1")}
              <br />
              {t("PLATFORM.TITLE5_2")}
            </h2>
            <p
              className="po6"
              style={{
                fontSize: 18,
                lineHeight: "155.5%",
                textAlign: "center",
              }}
            >
              {t("PLATFORM.SUBTITLE5")}
            </p>
          </div>
          <SeeDemo title={t("DEMO.SCHEDULENOW")} />
        </div>
      </section>

      {/* Our expertise */}
      <div style={{ minHeight: "100vh", width: "100%" }} ref={ideas}>
        <TransformIdeas />
        <SearchVideo />

        <section style={{ marginBottom: 185 }} id="expertise">
          <h2
            style={{ fontSize: breakpoints.sm ? 28 : 40 }}
            className="text-center"
          >
            {t("PLATFORM.EXPERTISE")}
          </h2>
          <h3
            className="fs18 text-center"
            style={{
              fontWeight: "normal",
              color: "rgba(255, 255, 255, 0.6)",
              marginTop: breakpoints.sm ? 112 : 128,
            }}
          >
            {t("PLATFORM.EXPERTISE_SUB")}
          </h3>

          <Quotes />
        </section>
        <CustomerSay />

        <p
          style={{
            textAlign: "center",
            marginBottom: 16,
            color: "#fff",
            opacity: 0.6,
            fontSize: 18,
            fontWeight: "300",
          }}
        >
          {t("TESTIMONIES.THEYSAY")}
        </p>
        <Testimonies location={location} />
        {/* <Chat
          chatStyle={chatStyle}
          clientChat={clientChat}
          initChat={initChat}
          openChat={openChat}
        /> */}
        <PipeDriveChat />
        <ScrollTopButton />
        <FloatingSeeDemo white />
        <Footer openChat={openChat} />
      </div>
    </>
  );
};

function Progress(props) {
  return (
    <svg
      width={42}
      height={173}
      viewBox="0 0 42 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={21}
        cy={21}
        r={20}
        stroke="#ECECEC"
        strokeOpacity={0.3}
        strokeWidth={2}
      />
      <rect x={18} y={19} width={6} height={154} rx={3} fill="#ECECEC" />
      <path fill="url(#prefix__paint0_linear)" d="M18 81h6v92h-6z" />
      <circle cx={21} cy={21} r={13} stroke="#ECECEC" strokeWidth={2} />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={21}
          y1={81}
          x2={21}
          y2={173}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function Circle(props) {
  return (
    <svg
      width={128}
      height={128}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={64}
        cy={64}
        r={63}
        stroke="#ECECEC"
        strokeOpacity={0.3}
        strokeWidth={2}
      />
      <circle cx={64} cy={64} r={41} stroke="#ECECEC" strokeWidth={2} />
      <path
        opacity={0.1}
        d="M65.501 75.275l9.388-9.405L77 67.984 64.009 81 51 67.984l2.11-2.115 9.406 9.41V46H65.5v29.275z"
        fill="#000"
      />
    </svg>
  );
}

export default Platform;
