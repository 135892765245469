import React, { useEffect, useState } from "react";
// import { FaArrowCircleUp } from 'react-icons/fa';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import { isBrowser } from "@utils/isBrowser";
import styles from "./n5-scroll-top-button.module.scss";

const ScrollToTop= () => {
  const [isVisible, setIsVisible] = useState(false);
  
  const toggleVisibility = () => {
    if (isBrowser && window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    isBrowser && window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    isBrowser && window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (    
    <>
        {
          isVisible && (
            <IoIosArrowDropupCircle 
              className={styles.backToTop} 
              onClick={scrollToTop}
            />
          )
        }    
    </>
  );
}

export default ScrollToTop;