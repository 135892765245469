import React from "react";
import styles from "./n5-img-scroll.module.scss";
import { BsChevronDown } from "react-icons/bs";


const ImgScroll = () => {
    return (
        <BsChevronDown className={styles.scroll}/>
    )
}

export default ImgScroll;