import React from 'react';

const SeeDemo = ({title}) => {
    return (
        <div             
            style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            zIndex: 2,
            textAlign: "center", 
            }}
        >
            <a 
                className="btnPlatform"          
                href="/schedule-demo"
                style={{
                    textDecoration: "none",
                    color: "#fff",
                    marginBottom: "5%"
                }}                  
            >
                {title}
            </a>
        </div>
    )
}

export default SeeDemo;